import React from "react";
import { ThemeContext } from "../layouts";
import Article from "../components/Article/";
import Headline from "../components/Article/Headline";
import "../dist/css/responsive.css";

const VideoPostPage = () => {
  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <header>
              <Headline title="Videos" theme={theme} />
            </header>
            <section className="videoContainer">
              <iframe 
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/Jv-fW1N3kmE" 
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen
              ></iframe>
    
              <iframe
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/uzOyfq07RGo" 
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen
              ></iframe>
    
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/NlRXJHH25S8"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
    
              <iframe 
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/uleLYqCNch4" 
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen
              ></iframe>
            </section>

            <style jsx>{`
              .videoContainer {
                text-align: center;
              }
              h2 {
                margin: 0 0 0.5em;
              }
              h2 :global(svg) {
                height: 0.8em;
                fill: ${theme.color.brand.primary};
              }
            `}</style>
          </Article>
        )}
      </ThemeContext.Consumer>
    </React.Fragment>
  );
};

export default VideoPostPage;
